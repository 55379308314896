<template>
  <div style="text-align: left; min-width: 410px">
    <div
      class="my-12 pb-12 pt-6"
      style="
        width: 85%;
        max-width: 1100px;
        margin: auto;
        font-family: 'Noto Sans JP', sans-serif;
        line-height: 45px;
        font-style: normal;
        font-size: 20px;
      "
    >
      <div v-if="$vuetify.breakpoint.mdAndUp" class="mb-4">
        <div style="text-align: center; min-width: 700px">
          <span style="line-height: 212px; min-width: 700px; font-size: 55px; font-weight: 900"
            >プライバシーポリシー</span
          >
        </div>
        <div
          style="
            text-align: center;
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            width: 1000px;
            height: 200px;
            margin: auto;
          "
        >
          <span style="font-family: Helvetica; line-height: 300px; font-size: 150px; font-weight: bold; opacity: 0.08"
            >PRIVACY</span
          >
        </div>
      </div>
      <div v-if="!$vuetify.breakpoint.mdAndUp" class="mb-1">
        <div style="text-align: center; width: 100%; min-width: 370px">
          <span style="line-height: 100px; min-width: 370px; font-size: 30px; font-weight: 900"
            >プライバシーポリシー</span
          >
        </div>
        <div
          style="
            text-align: center;
            position: absolute;
            top: -35px;
            left: 0;
            right: 0;
            width: 100%;

            height: 282px;
            margin: auto;
          "
        >
          <span style="font-family: Helvetica; line-height: 300px; font-size: 50px; font-weight: bold; opacity: 0.08"
            >PRIVACY</span
          >
        </div>
      </div>
      <table v-if="$vuetify.breakpoint.mdAndUp" style="width: 100%; font-size: 15px" cellspacing="0">
        <tr v-for="(item, index) in form" :key="index">
          <th style="width: 30%; font-weight: normal" class="py-6 px-6">
            {{ item.title }}
          </th>
          <td style="width: 70%" class="py-6 px-6">
            <span v-if="index !== 1">{{ item.text1 }}<br />
            {{ item.text2 }}</span>
            <span v-if="index === 1">{{ item.text1 }}<br />
            {{ item.text2 }}<br />
            {{ item.text3 }}</span>
          </td>
        </tr>
      </table>
      <table v-if="!$vuetify.breakpoint.mdAndUp" style="width: 100%; font-size: 13px" cellspacing="0">
        <tr v-for="(item, index) in form" :key="index">
          <th style="width: 30%; font-weight: normal" class="py-3 px-3">
            {{ item.title }}
          </th>
          <td style="width: 70%" class="py-6 px-6">
            <span v-if="index !== 1">{{ item.text1 }}<br />
            {{ item.text2 }}</span>
            <span v-if="index === 1">{{ item.text1 }}<br />
            {{ item.text2 }}<br />
            {{ item.text3 }}</span>
          </td>
        </tr>
      </table>
      <Tip :dialog="dialog" @closeDialog="closeDialog"></Tip>
    </div>
  </div>
</template>

<script>
import Tip from './ShippingFee.vue'

export default {
  components: {
    Tip,
  },
  data: () => ({
    dialog: false,
    form: [
      {
        title: 'リード文',
        text1: '有限会社ORIENTAL（以下弊社といいます）では当店の｢個人情報保護方針｣に従い、お預かりしたお客様の個人情報を下記の通りお取り扱い致します。',
      },
      {
        title: '個人情報の利用目的',
        text1: 'お客様よりお預かりした個人情報は、以下の目的に限定し利用させていただきます。',
        text2: '・本サービスに関する顧客管理',
        text3: '・本サービスの運営上必要な事項のご連絡',
      },
      {
        title: '個人情報の提供について',
        text1: '弊社ではお客様の同意を得た場合または法令に定められた場合を除き、取得した個人情報を第三者に提供することはいたしません。',
      },
      {
        title: '個人情報の委託について',
        text1: '弊社では、利用目的の達成に必要な範囲において、個人情報を外部に委託する場合があります。これらの委託先に対しては個人情報保護契約等の措置をとり、適切な監督を行います。',
      },
      {
        title: '個人情報の安全管理',
        text1: '弊社では、個人情報の漏洩等がなされないよう、適切に安全管理対策を実施します。',
      },
      {
        title: '個人情報を与えなかった場合に生じる結果',
        text1: '必要な情報を頂けない場合は、それに対応した弊社のサービスをご提供できない場合がございますので予めご了承ください。',
      },
      {
        title: '個人情報の開示･訂正・削除･利用停止の手続について',
        text1: '弊社では、お客様の個人情報の開示･訂正･削除・利用停止の手続を定めさせて頂いております。ご本人である事を確認のうえ、対応させて頂きます。',
        text2: '個人情報の開示･訂正･削除・利用停止の具体的手続きにつきましては、カスタマーデスク（info@bimistock.com）宛にメールにてご連絡ください。',
      },
    ],
  }),
  methods: {
    closeDialog() {
      this.dialog = false
    },
  },
}
</script>

<style scoped>
table {
  border-top: 1px solid #cbcbcb;
  min-width: 300px;
}
th,
td {
  border: 1px solid #cbcbcb;
}
td {
  border-left: 0;
  border-top: 0;
}
th {
  border-top: 0;
  background-color: #ebebeb;
}
.pointer :hover {
  cursor: pointer;
}
</style>
